.modal-content {
}

.mdby {
  height: 400px !important;
  padding-top: 20px;
}

.content-2 {
  display: flex;
  flex-direction: column;
}

.st-no {
  text-align: center;
  font-size: 18px;
}

.wn-no {
  font-size: 13px;
  margin-bottom: 40px;
}

.sn-q {
  text-align: center;
}

.input-field {
  border: 1px solid #ccc;
  height: 35px;
  border-radius: 3px;
  padding: 3px 7px;
  text-align: center;
}

.in-one {
  margin-bottom: 20px;
}

.cn-btn {
  display: block;
  background-color: #0071e0;
  text-align: center;
  color: #fff;
  margin-top: 35px;
  border-radius: 3px;
  padding: 12px 0;
  cursor: default;
}

.ds-btn {
  background-color: #ccc !important;
  pointer-events: none;
}

/* ----------------- new updates ---------------------------------------- */
.card-inside-header {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #0f172a;
}

.card-2nd-inside-header {
  font-size: 28px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;

  color: #0f172a;
}

.card-3nd-inside-header {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;

  color: #0f172a;
}
.newcontent {
  padding: 1rem;
}

.no-show-botton {
  background-color: transparent !important;
  border: none;
}

.no-show-botton:hover {
  border: none;
}

.nohover:hover {
  background-color: transparent !important;
}

/* .section-header {
  height: 4.5rem;
} */

.section-header .form-select,
.section-header .form-select-analytics__control {
  width: 8rem;
  font-size: 0.75rem;
  background-color: #f4f7fa;
  border: 1px solid #e2e8f0;
  font-weight: 500;
  border-radius: 0.375rem;
}

.section-header .form-select-analytics__option--is-selected {
  background-color: #2463ae;
}

.section-header .form-select-analytics__indicator-separator {
  display: none;
}

.section-header .form-select-analytics__value-container {
  padding-right: 0;
}

.section-header .form-select-analytics__menu {
  font-size: 0.75rem;
  z-index: 2;
}

.section-header .btn {
  width: fit-content;
  font-size: 0.75rem;
  font-weight: 500;
  border-radius: 0.375rem;
}

.parent-row {
  display: grid;
  grid-template-columns: auto 28%;
  grid-template-rows: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 0px;
  margin-bottom: 1rem;
}

.first-child-row {
  display: grid;
  grid-template-columns: 218px auto;
  grid-template-rows: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 0px;
  margin-bottom: 1rem;
}

.secound-child-row {
  display: grid;
  grid-template-columns: 336px auto;
  grid-template-rows: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 0px;
  margin-bottom: 1rem;
}

.first-height-dashbord {
  height: 298px;
}

.second-height-dashbord {
  height: 410px;
}
.pale-blue {
  background: #ecf4fe;
}

.dark-blue-bash {
  background-color: #ecf4fe;
  border-radius: 5px;
  text-align: center;
  font-weight: bolder;
  padding: 3px 0px;
  color: #2463ae;
}

.red-bash {
  background-color: #ffdcdc;
  border-radius: 5px;
  text-align: center;
  font-weight: bolder;
  padding: 3px 0px;
  color: #ac7b7b;
}

.orange-bash {
  background-color: #fffbdc;
  border-radius: 5px;
  text-align: center;
  font-weight: bolder;
  padding: 3px 0px;
  color: #aca47b;
}

.light-blue-bash {
  background-color: #f4f7fa;
  border-radius: 5px;
  text-align: center;
  font-weight: bolder;
  padding: 3px 0px;
  color: #94a3b8;
}

.green-bash {
  background-color: #dcffdf;
  border-radius: 5px;
  text-align: center;
  font-weight: bolder;
  padding: 3px 0px;
  color: #7bac80;
}

/* .pagination {
  display: flex;
  padding: 1.25rem;
  justify-content: space-between;
}

.pagination .pagination_left {
  display: flex;
  gap: 0.5rem;
  align-items: center;
} */

.performance {
  height: 410px;
}

.pagination {
  gap: 0.5rem;
}

.pagination .next .page-link {
  border: 1px solid #2463ae;
  color: #2463ae;
}

.pagination .next .page-link:hover {
  color: #fff;
  background-color: #2463ae;
}

.pagination .border-none .page-link {
  border: none !important;
}

.pagination .pagination_left p {
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.375rem;
}

/* .pagination .pagination_left p .cusFound {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #94a3b8;
}

.pagination .pagination_left select {
  width: 7.563rem;
  height: 2.625rem;
  background-color: #f4f7fa;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.375rem;
  margin: 0;
}

.pagination .pagination_right {
  display: flex;
  gap: 1.5rem;
}

.pagination .pagination_right .buttonSelect {
  background-color: #2463ae;
  border-radius: 4px;
  padding: 10px 20px;
  color: white;
  border: none;
}

.pagination .pagination_right .buttonShow {
  background-color: #ecf4fe;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.4rem;
  color: #2463ae;
  padding: 10px 20px;
  border: none;
  border-radius: 0.25rem;
} */

.pagination .page-item {
  line-height: 0;
}

.pagination .page-item.active .page-link {
  background: #2463ae;
  color: #fff;
}

.pagination .page-item .page-link {
  width: 2rem;
  height: 2rem;
  color: #94a3b8;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
}

.pagination .page-item .page-link svg {
  transform: scale(0.6);
}

.notice-board-topic {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #0f172a;
}

.notice-board-semi-topic {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #0f172a;
}

.notice-board-under {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #0f172a;
}

.activities-dashboard {
  height: 630px;
  margin-bottom: 1rem;
}

/* ---------- CALENDER------------------- */

.calender-class .rdtPicker table .rdtDay,
.calender-class .rdtPicker table th {
  padding: 10px !important;
  height: 10px !important;
}

.calender-class .rdtPicker {
  width: auto;
  box-shadow: none;
  border: none;
  padding: 0px;
  left: 0px;
  border-radius: 0.5rem;
  z-index: 0px;
}

.calender-class .form-control {
  border: none;
}

.calender-class .rdtPicker td.rdtActive {
  border-radius: 80%;
}

.calender-class .rdtPicker .rdtTimeToggle {
  display: none;
}

.calender-class .rdtPicker tfoot {
  border-top: none;
}

.activity-line {
  border-top: 2px solid #e2e8f0;
  width: 265px;
}

.main-activity {
  padding: 1rem;
}

.activity-text {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding: 1rem 0px;
  margin: 0px;
}

.activity-box {
  width: 265px;
  height: 90px;
  padding: 12px 8px 12px 8px;
  border-radius: 4px;
  border: 1px solid #e2e8f0;
}

.activity-details {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.activity-box-time {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.activity-foot {
  padding: 9px 20px 9px 20px;
  border-radius: 4px;
  color: #2463ae;

  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.activity-foot button {
  padding: 9px 20px 9px 20px;
  border-radius: 4px;
  color: #2463ae;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.birthday-card {
  height: 196px !important;
}

.birthday-text {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.birthday-slider {
  width: 224px;
  height: 65px;
  padding: 16px 12px 16px 12px;
  border-radius: 4px;
  gap: 12px;
  background: #ffffff;
}

.birthday-name {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.birthday-date {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  align-items: center;
}
