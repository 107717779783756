.calender header {
  height: fit-content;
  position: relative;
  background-color: #fff;
  border-radius: 0.5rem; }
  .calender header h1 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem; }
  .calender header p {
    font-size: 1rem; }

.calender .ga-wrapper {
  padding: 0; }

.calender main {
  padding: 1.5rem; }
  .calender main iframe {
    width: 100%;
    background-color: #fff;
    border-radius: 0.5rem;
    height: 100vh;
    pointer-events: all;
    border: 1px solid #E2E8F0; }
