body {
  font-family: Open-sauce-sans, sans-serif;
  font-size: 0.7rem;
  color: #1f1f1f;
  background-color: #f7f7f7;
  min-height: 100%;
  overflow-x: hidden; }

button,
a {
  cursor: pointer; }

.h-dash-sidebar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0; }
  .h-dash-sidebar .span_row {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 0.5rem;
    justify-content: space-between; }
  .h-dash-sidebar h3 {
    font-size: 0.9rem;
    color: #0f172a;
    font-weight: 500;
    margin-bottom: 0rem;
    padding: 0rem; }

ul {
  display: flex;
  flex-direction: column;
  padding: 0rem; }
  ul li {
    list-style: none;
    accent-color: #2463ae;
    color: #515b6f;
    font-size: 0.75rem; }
    ul li p {
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: 0.75rem; }

header {
  background: white;
  width: 100%;
  padding: 1.3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 9;
  height: 3.125rem; }

.primary-bi-color {
  color: #2463ae;
  font-size: 0.9rem; }

label {
  margin-bottom: 0px;
  margin: 0px; }

.job_body {
  background: #ecf4fe; }

.job-logo {
  width: 7rem; }

.bi-house-door-fill {
  color: #2463ae;
  font-size: 1.25rem; }

.home_btn_wrapper {
  background: #ecf4fe;
  border-radius: 50%;
  padding: 0.25rem;
  height: 2.5rem;
  width: 2.5rem;
  display: block; }

.left_bar {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center; }
  .left_bar .profile_chev {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
    font-size: 0.9rem;
    font-weight: bold; }
  .left_bar .profile_image {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    object-fit: cover; }

.main__section_container {
  display: flex;
  margin-top: 4rem;
  padding: 1.5rem;
  gap: 1.5rem;
  width: 100%; }
  .main__section_container .left_SideBar {
    padding: 1.5rem;
    width: 20.625rem;
    height: auto;
    border-radius: 0.5rem;
    border: 1px solid #e2ebf0;
    background: white; }
    .main__section_container .left_SideBar .box-container {
      gap: 1rem;
      display: flex;
      flex-direction: column; }
      .main__section_container .left_SideBar .box-container .h-dash {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0; }
        .main__section_container .left_SideBar .box-container .h-dash h3 {
          font-size: 0.9rem;
          color: #0f172a;
          margin: 0; }
      .main__section_container .left_SideBar .box-container ul {
        display: flex;
        flex-direction: column; }
        .main__section_container .left_SideBar .box-container ul li {
          list-style: none;
          accent-color: #515b6f;
          color: #515b6f;
          font-size: 0.75rem; }
          .main__section_container .left_SideBar .box-container ul li p {
            display: flex;
            align-items: center;
            gap: 0.75rem; }

.Center__Bar {
  width: 100%; }
  .Center__Bar h3 {
    font-weight: 600; }

.tools-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.input_stash {
  width: 16.125rem;
  height: 2.75rem;
  padding: 0.68rem 0.87rem 0.68rem 0.87rem;
  gap: 0.5rem;
  border-radius: 0.25rem;
  border: 0.625rem solid #ecf4fe 0rem 0rem 0rem; }

.search__wrapper {
  display: flex;
  gap: 0.4rem;
  align-items: center;
  justify-content: center; }

.input-box {
  display: flex;
  gap: 0.3rem;
  width: 15.24rem; }
  .input-box .input_search {
    border: 0px;
    background: transparent; }

.search-btn {
  width: 6.25rem;
  height: 2.4rem;
  background: #2463ae;
  display: flex;
  gap: 0.5rem;
  color: white;
  transform: translateY(0.25rem); }
  .search-btn:hover {
    background: #436a99; }

.tool_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3rem; }

.line {
  display: block;
  height: 1.2rem;
  width: 0.01475rem;
  background: #9ca3af;
  transform: translateY(0.5rem); }

.line-long {
  display: block;
  height: 1.7rem;
  width: 0.01875rem;
  background: #e2e8f0; }

.sort-by {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  justify-content: center;
  transform: translateY(1rem); }
  .sort-by .bold-text {
    font-weight: 700; }

.layouts {
  transform: translateY(0.5rem);
  cursor: pointer; }
  .layouts .active {
    background: #c7d2fe;
    display: flex;
    align-items: center;
    justify-content: center; }

.list_layout {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column; }
  .list_layout .content__box {
    width: 100%;
    border: 1px solid #e2ebf0;
    background: white;
    height: auto;
    padding: 0.7rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.4rem; }
    .list_layout .content__box .content-1 {
      display: flex;
      gap: 0.2rem;
      flex-direction: column; }
      .list_layout .content__box .content-1 .line-ip {
        display: flex;
        align-items: center;
        gap: 0.3rem; }
        .list_layout .content__box .content-1 .line-ip span {
          background: #c7d2fe;
          font-size: 0.76rem;
          font-weight: 600;
          color: #2463ae;
          display: flex;
          align-items: center;
          justify-content: center; }
        .list_layout .content__box .content-1 .line-ip .dot {
          color: #9ca3af;
          font-size: 1.2rem;
          font-weight: 700; }
        .list_layout .content__box .content-1 .line-ip .span_bar {
          display: flex;
          align-items: center;
          gap: 0.2rem;
          color: #9ca3af;
          font-size: 0.76rem; }
          .list_layout .content__box .content-1 .line-ip .span_bar p {
            margin-bottom: 0rem; }
      .list_layout .content__box .content-1 h3 {
        font-size: 1.2rem; }
    .list_layout .content__box .content-2 .search-bt {
      width: 6.25rem;
      height: 2.4rem;
      background: #2463ae;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      justify-content: center;
      color: white; }

.grid_conr {
  display: grid;
  width: 100%;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(20.81rem, 1fr));
  align-items: center; }

.list_grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  flex-direction: column; }

.grid_layout {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  align-items: center; }
  .grid_layout .content__box {
    width: 100%;
    background: white;
    height: auto;
    padding: 0.7rem;
    border: 1px solid #e2ebf0;
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 0.1rem; }
    .grid_layout .content__box .content-1 {
      align-items: start;
      display: flex;
      gap: 0.2rem;
      flex-direction: column; }
      .grid_layout .content__box .content-1 .line-ip {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        align-items: start;
        margin-bottom: 0.4rem; }
        .grid_layout .content__box .content-1 .line-ip span {
          background: #c7d2fe;
          font-size: 0.76rem;
          font-weight: 600;
          color: #2463ae;
          display: flex;
          flex-direction: column;
          align-items: start; }
        .grid_layout .content__box .content-1 .line-ip .dot {
          color: #9ca3af;
          font-size: 1.2rem;
          display: none;
          font-weight: 700; }
        .grid_layout .content__box .content-1 .line-ip .span_bar {
          display: flex;
          align-items: start;
          margin-top: 0.7rem;
          gap: 0.2rem;
          color: #9ca3af;
          font-size: 0.76rem; }
          .grid_layout .content__box .content-1 .line-ip .span_bar p {
            margin-bottom: 0rem; }
      .grid_layout .content__box .content-1 h3 {
        font-size: 1.2rem; }
    .grid_layout .content__box .content-2 .search-bt {
      height: 2.4rem;
      width: auto;
      background: #2463ae;
      display: flex;
      gap: 0.1rem;
      align-items: center;
      text-align: center;
      justify-content: center;
      color: white; }

.soft-text {
  color: #9ca3af;
  font-weight: 400;
  margin-bottom: 0rem; }

.softer-text {
  color: #94a3b8;
  font-weight: 400;
  font-size: 0.9rem;
  margin-bottom: 0rem;
  display: flex;
  align-items: center; }

.softcore-text {
  color: #0f172a;
  font-weight: 600;
  margin-bottom: 0rem;
  font-size: 0.9375rem; }

.secondary-text {
  color: #334155;
  font-weight: 500;
  margin-bottom: 0rem;
  font-size: 0.8675rem; }

.gap-5 {
  gap: 1rem;
  display: flex;
  margin-bottom: 0rem; }

.gap-4 {
  gap: 0.5rem;
  display: flex;
  margin-bottom: 0rem; }

.gap-3 {
  gap: 0.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 0rem; }

.tw-gap-1 {
  gap: 1rem;
  display: flex;
  margin-bottom: 0rem; }

.tw-gap-2 {
  gap: 0.7rem;
  display: flex;
  margin-bottom: 0rem; }

.gap-2 {
  gap: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0rem; }

.wrapper_view {
  height: 6.1875rem; }

.dot {
  color: #9ca3af;
  font-size: 1.2rem;
  display: block;
  font-weight: 700;
  margin-bottom: 0rem;
  margin: 0rem; }

.job_title {
  font-size: 1.3rem;
  font-weight: 700; }

.job__type {
  background: #c7d2fe;
  font-size: 0.76rem;
  font-weight: 600;
  color: #2463ae;
  margin-bottom: 0rem;
  display: flex;
  flex-direction: column; }

.other_det {
  color: #0f172a;
  font-weight: 500;
  font-size: 0.9rem; }

.other_det_2 {
  color: #0f172a;
  font-weight: 500;
  font-size: 1.2rem; }

.other_det_1 {
  color: #0e1016;
  font-weight: 700;
  font-size: 1.3rem; }

.other_det_sm {
  color: #0f172a;
  font-weight: 600;
  font-size: 0.9rem; }

.other_det_xs {
  color: #0f172a;
  font-weight: 400;
  font-size: 0.95rem;
  line-height: 1.5rem; }

.other_det_md {
  color: #0f172a;
  font-weight: 500;
  font-size: 0.9rem; }

.other_det_xl {
  color: #0f172a;
  font-weight: 500;
  font-size: 0.875rem; }

.det-wrap {
  display: flex;
  gap: 1rem;
  flex-direction: row; }
  .det-wrap .job_role {
    width: 35rem;
    height: 18rem;
    border-radius: 0.3rem;
    flex-shrink: 0; }
  .det-wrap .description_box {
    border-radius: 0.3rem;
    flex-grow: 1;
    width: auto;
    height: auto; }

.modal_clse {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 50%;
  float: right;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  background: #94a3b8; }
  .modal_clse:hover {
    background: #436a99; }

.bg-white {
  background: white; }

.add_vacancy {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  background: white;
  height: 6.095rem;
  padding: 0.5rem; }

.pb-0 {
  margin-bottom: 0rem;
  padding-bottom: 0rem; }

.flex-col {
  flex-direction: column; }

.w-full {
  width: 100%; }

.layout-gap {
  gap: 1rem; }

.blue-pale {
  background: #f4f7fa;
  cursor: pointer; }
  .blue-pale:hover {
    background: #cad5e0;
    transition: all 800ms ease-in-out; }

.blue-pale-static {
  background: #f4f7fa;
  cursor: pointer; }
  .blue-pale-static:hover {
    background: #cad5e0;
    transition: all 800ms ease-in-out; }

.mt-1x {
  margin-top: 4rem;
  z-index: 99; }

.recent-applications {
  width: 20rem;
  height: auto;
  background: white; }
  .recent-applications .top_header {
    background: white;
    height: auto;
    align-items: center;
    width: 100%;
    padding: 0.8rem;
    display: flex; }
    .recent-applications .top_header h4 {
      color: #0f172a;
      font-size: 0.9rem; }
  .recent-applications section {
    width: 100%;
    padding: 0.8rem; }
    .recent-applications section .section_wrapper {
      display: flex;
      color: #334155;
      align-items: center;
      gap: 1rem; }
      .recent-applications section .section_wrapper .detail_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 11rem; }
  .recent-applications main {
    padding: 1rem; }
    .recent-applications main .applicants {
      width: 100%;
      gap: 1rem; }
      .recent-applications main .applicants .row-details {
        display: flex;
        width: 15rem;
        align-items: center;
        gap: 0.5rem;
        height: auto; }
        .recent-applications main .applicants .row-details .row-details_image_holder img {
          width: 1.75rem;
          height: 1.74rem; }
      .recent-applications main .applicants .applicant__positon {
        float: left;
        align-items: start;
        display: flex;
        width: 8.75rem; }

.shortlisted {
  font-weight: 500;
  color: #2463ae;
  font-size: 0.75rem;
  background: #ecf4fe;
  width: fit-content;
  border-radius: 0.1875rem;
  height: 1.5625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem; }

.cancelled {
  font-weight: 500;
  color: #ffff;
  font-size: 0.75rem;
  background: #ac7b7b;
  width: fit-content;
  border-radius: 0.1875rem;
  height: 1.5625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem; }

.rejected {
  font-weight: 500;
  color: #ac7b7b;
  font-size: 0.75rem;
  background: #ffdcdc;
  width: fit-content;
  border-radius: 0.1875rem;
  height: 1.5625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem; }

.pending {
  font-weight: 500;
  color: #aca47b;
  font-size: 0.75rem;
  background: #fffbdc;
  width: fit-content;
  border-radius: 0.1875rem;
  height: 1.5625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem; }

.open-active {
  font-weight: 500;
  color: #7bac80;
  font-size: 0.75rem;
  background: #dcffdf;
  width: fit-content;
  border-radius: 0.1875rem;
  height: 1.5625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem; }

.offered {
  font-weight: 500;
  color: #2e7c35;
  font-size: 0.75rem;
  background: #bbd3bd;
  width: fit-content;
  border-radius: 0.1875rem;
  height: 1.5625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem; }

.onboarding {
  font-weight: 500;
  color: #47d155;
  font-size: 0.75rem;
  background: #e1f7e3;
  width: fit-content;
  border-radius: 0.1875rem;
  height: 1.5625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem; }

.vacancy_details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
  gap: 1rem; }
  .vacancy_details .grid_box {
    height: 9rem;
    border-radius: 0.5rem;
    background: white;
    padding: 1.2rem; }
    .vacancy_details .grid_box .icon_box img {
      width: 2.8rem;
      object-fit: cover;
      border-radius: 50%;
      height: 2.8rem; }

.chart_bar {
  height: 18.625rem;
  background: white;
  padding: 1.2rem; }
  .chart_bar .select_bar {
    background: #e2e8f0;
    outline: none;
    color: #0f172a;
    font-size: 0.85rem;
    width: 7rem;
    outline-style: none;
    outline-width: 0rem;
    height: 2.2rem;
    font-weight: 500; }

.grey {
  color: #94a3b8;
  font-weight: 400;
  font-size: 0.9rem; }

.grey-xs {
  color: #94a3b8;
  font-weight: 400;
  font-size: 0.8rem; }

.grey-sm {
  color: #334155;
  font-weight: 400;
  font-size: 0.875rem; }

.grey-xl {
  color: #94a3b8;
  font-weight: 500;
  font-size: 0.9rem; }

.personal_info {
  color: #25324b;
  font-weight: 600;
  font-size: 0.95rem; }

.reload-svg {
  width: 1.04rem;
  height: 1rem; }

.scroll-svg {
  width: 1rem;
  height: 1rem; }

.vacancy_wrapper {
  transform: translateY(-5.5rem); }
  .vacancy_wrapper .vacancy__list {
    padding: 1.5rem; }

.table__head {
  background: #f1f1f3 !important;
  border-radius: 5px; }

.opacity-0 {
  opacity: 0; }

.w-15 {
  width: 10rem; }

.w__12 {
  width: 14rem; }

.header_wrapper {
  display: flex;
  align-items: center;
  gap: 1.5rem; }

.nav_back {
  display: flex;
  gap: 0.5rem;
  align-items: center; }
  .nav_back .nav_back-text {
    font-size: 0.9rem;
    font-weight: 700;
    color: #2463ae; }

.left_wrapper {
  display: flex;
  align-items: center;
  gap: 1rem; }
  .left_wrapper .gray_color {
    color: #94a3b8;
    font-size: 0.875rem;
    font-weight: 500; }
  .left_wrapper .box_left-wrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer; }
    .left_wrapper .box_left-wrapper img {
      width: 1.4rem;
      height: 1.4rem; }

.gray_color {
  color: #94a3b8;
  font-size: 0.875rem;
  font-weight: 500; }

.blue-color {
  background: #f8faff;
  border-radius: 0.3215rem;
  padding: 0.75rem; }
  .blue-color .p-color {
    color: #2463ae;
    font-weight: 500;
    color: #2463ae;
    font-size: 0.875rem;
    font-weight: 500; }

.chart_container {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr); }
  .chart_container .Chart_box {
    width: 25.23rem;
    height: 6.5rem;
    padding: 0.8855rem; }
    .chart_container .Chart_box .Chart_box_wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem; }

.hidden {
  display: hidden;
  opacity: 0; }

.show {
  opacity: 1;
  display: block; }

.Chart_box_pipeline {
  height: auto;
  padding: 0.8855rem; }
  .Chart_box_pipeline .pipelne_box {
    height: 16.17rem;
    padding: 1.25rem;
    width: 100%; }

.flex-gap-3 {
  gap: 1.2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr); }

.gap-xs {
  gap: 0.3rem; }

.gap-sm {
  gap: 0.5rem; }

.gap-md {
  gap: 1rem; }

.gap-lg {
  gap: 1.5rem; }

.gap-xl {
  gap: 2rem; }

.svg-w-sm {
  width: 1.4rem;
  height: 1.4rem; }

.hover-text {
  color: #2463ae;
  font-weight: 500;
  font-size: 0.9rem; }
  .hover-text:hover {
    color: #fff; }

.applicant_wrapper {
  width: 100%;
  display: flex; }
  .applicant_wrapper .applicant_details_right {
    width: 25rem;
    height: 33rem;
    padding-top: 2rem;
    padding-bottom: 2rem; }
    .applicant_wrapper .applicant_details_right .details_img {
      width: 5rem;
      height: 5rem;
      object-fit: cover;
      border-radius: 50%; }
    .applicant_wrapper .applicant_details_right main {
      background: #f8faff;
      height: auto; }
  .applicant_wrapper .applicant_details_left {
    flex-grow: 1;
    width: 45rem;
    height: auto; }

.active_navtab {
  position: relative;
  font-weight: 500;
  font-size: 0.9rem;
  color: #2463ae; }

.active_navtab::after {
  content: "";
  position: absolute;
  bottom: -0.9rem;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #2463ae;
  transition: all 0.3s ease; }

.navtab {
  position: relative;
  font-weight: 500;
  font-size: 0.9rem;
  color: #94a3b8;
  transition: color 0.3s ease;
  cursor: pointer; }

.navtab:hover,
.navtab.active_navtab {
  color: #2463ae; }

.schedule-interview {
  width: 100%; }
  .schedule-interview .schedule_heading {
    font-size: 1.225rem;
    font-weight: 600;
    color: #25324b; }
  .schedule-interview .schedule_box .schedule_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; }
    .schedule-interview .schedule_box .schedule_item .schedule_content {
      width: 100%; }
      .schedule-interview .schedule_box .schedule_item .schedule_content .shc_marker {
        border-radius: 50%;
        height: 0.5rem;
        width: 0.5rem;
        background: #2463ae;
        display: block; }

.documents {
  width: 100%; }
  .documents .document_wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    .documents .document_wrapper .document_content {
      width: 19rem;
      height: 17rem;
      position: relative; }
      .documents .document_wrapper .document_content .document_image {
        background: #e2e8f0;
        height: 100%; }
        .documents .document_wrapper .document_content .document_image img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .documents .document_wrapper .document_content .document_details {
        width: 100%;
        height: 5rem;
        position: absolute;
        bottom: 0;
        background: white;
        z-index: 999; }

.modal_title h1 {
  color: #111827;
  font-size: 1.2rem;
  margin-bottom: 0.3px;
  font-weight: 600; }

.modal_title p {
  color: #414652;
  font-size: 0.9rem;
  font-weight: 500; }

.Heading_modal {
  color: #0f172a;
  font-size: 1.2rem;
  margin: 0px;
  font-weight: 500;
  margin-bottom: 0.5rem; }

.tw-form-label-radio {
  color: #0f172a;
  font-size: 0.6875rem;
  font-weight: 500;
  margin-left: 0.4rem; }

.ghost-input {
  outline: none;
  border: none;
  background: transparent; }

.span-line-input {
  height: 15px;
  width: 0.6px;
  background: #334155; }

.tw-modal-body .tw-modal-content {
  width: 27rem;
  height: auto;
  text-align: start;
  padding-bottom: 2rem;
  padding-top: 2rem;
  padding: 0.8rem;
  border-radius: 4px; }
  .tw-modal-body .tw-modal-content form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem; }
    .tw-modal-body .tw-modal-content form textarea {
      outline: none; }
  .tw-modal-body .tw-modal-content .tw-form-label {
    color: #334155;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 0.8rem; }
    .tw-modal-body .tw-modal-content .tw-form-label span {
      color: #f26e6e; }
  .tw-modal-body .tw-modal-content .tw-form-control {
    padding-left: 1rem;
    padding-right: 1rem;
    width: 25rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    font-size: 0.8rem;
    color: #94a3b8;
    border-radius: 4px; }
    .tw-modal-body .tw-modal-content .tw-form-control::-moz-placeholder {
      font-size: 0.8rem;
      color: #94a3b8; }

.tw-form-label {
  color: #334155;
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 0.8rem; }
  .tw-form-label span {
    color: #f26e6e; }

.tw-form-control {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 25rem;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  font-size: 0.8rem;
  color: #94a3b8;
  border-radius: 4px; }
  .tw-form-control::-moz-placeholder {
    font-size: 0.8rem;
    color: #94a3b8; }

.tw-form-control-full {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.8rem;
  color: #94a3b8;
  border-radius: 4px; }
  .tw-form-control-full::-moz-placeholder {
    font-size: 0.8rem;
    color: #94a3b8; }

.tw-width {
  width: 62rem; }

.tw-form-control-sm {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  width: 12rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.8rem;
  color: #94a3b8;
  border-radius: 4px; }
  .tw-form-control-sm img {
    transform: translateX(-2rem); }
  .tw-form-control-sm::-moz-placeholder {
    font-size: 0.8rem;
    color: #94a3b8; }

.tw-submit {
  margin-top: 8rem;
  display: flex;
  align-items: center;
  transform: translateX(12rem);
  gap: 1rem;
  float: right; }

.tw-submit-1 {
  display: flex;
  align-items: center;
  gap: 1rem;
  float: right; }

/* Frame 21 */
.tw-btn-cancel {
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;
  color: #0e1016;
  font-size: 0.8215rem;
  font-weight: 500;
  width: 89px;
  height: 43px;
  /* Black/Pale */
  background: #f4f7fa;
  border: 1px solid #cccccc;
  border-radius: 4px; }

.calender-img-1 {
  object-fit: contain;
  width: 19rem;
  height: auto; }

.calender-img-2 {
  object-fit: cover;
  flex-grow: 1;
  height: auto; }

/* Frame 22 */
.tw-btn-primary {
  width: 98px;
  height: 42px;
  background: #0c61a4;
  border-radius: 4px;
  font-size: 0.8215rem;
  color: white;
  font-weight: 500;
  float: right; }

/* Auto layout */
.dropdown {
  position: relative;
  display: inline-block;
  /* To ensure button and content align properly */ }

.dropdown-content {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  max-height: 0;
  /* Start with collapsed height */
  overflow: hidden; }
  .dropdown-content.open {
    opacity: 1;
    transform: translateY(0);
    max-height: 200px;
    /* Adjust based on your content size */ }

.main-pipeline {
  background: white; }

.add-btn {
  color: white; }

.performance-body {
  width: 100%;
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem; }
  .performance-body .performance-wrapper {
    width: 100%;
    min-height: 80vh;
    background: white;
    border: 1px solid #e2e8f0;
    padding: 1rem;
    border-radius: 1rem; }

.tw-border {
  border: 1.3px solid #e2e8f0 !important; }

.performance-navtab {
  display: flex;
  align-items: center;
  gap: 1rem; }

.tw-text-primary {
  color: #2463ae; }

.tw-bg-primary {
  background: #2463ae; }

.stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  background: white;
  align-items: center;
  justify-content: center; }
  .stats .stats-box {
    border-radius: 1rem;
    display: flex;
    padding-top: 1rem;
    padding-bottom: 1rem;
    gap: 1rem;
    background: white; }
    .stats .stats-box h1 {
      font-size: 1.5rem;
      color: #0f172a;
      font-weight: 500;
      margin-bottom: 2px; }
    .stats .stats-box p {
      color: #94a3b8;
      font-size: 0.9rem;
      font-weight: 400; }

.tw-rounded-lg {
  border-radius: 1rem; }

.stat-chart .tw-chart-grow {
  height: 23rem; }

.stat-chart .tw-chart-box {
  width: 23rem;
  height: 23rem; }
  .stat-chart .tw-chart-box h1 {
    font-size: 0.9rem;
    font-family: 500;
    color: #0f172a; }

.tw-inprogress {
  background: #fbb624; }

.tw-not-started {
  background: #94a3b8; }

.tw-completed {
  background: #17e28c; }

.tw-total-goals {
  background: #2463ae; }

.tw-avergae {
  background: #9553ff; }

.stat-status .round {
  width: 8px;
  height: 8px;
  border-radius: 30px; }

.stat-status p {
  color: #94a3b8;
  font-size: 0.6875rem;
  margin: 0px;
  margin-bottom: 0px;
  font-weight: 400; }

.rounded-image {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  object-fit: cover; }

.heading-chart {
  font-size: 0.9rem;
  color: #0f172a;
  font-weight: 500;
  margin: 0; }

.select-chart {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  width: 106px;
  color: #334155;
  font-weight: 500;
  font-size: 0.6875rem;
  height: 38px;
  background: #f4f7fa;
  border: 1px solid #cccccc;
  border-radius: 6px; }

.percent-stat {
  text-align: center;
  width: 13.8rem;
  height: 15rem; }
  .percent-stat h1 {
    font-size: 4rem;
    font-weight: 400;
    color: #0f172a; }
  .percent-stat p {
    color: #94a3b8;
    font-size: 0.8215rem;
    margin: 0px;
    margin-bottom: 0px;
    font-weight: 500; }

.tw-view-all {
  font-size: 0.875rem;
  font-weight: 500; }

.tw-pie-chart {
  flex-grow: 1; }
  .tw-pie-chart img {
    object-fit: cover;
    height: 18rem;
    width: 35.8rem;
    margin-left: 1rem; }

.tw-group-1 {
  margin-top: 1rem;
  width: 22rem;
  height: 23rem; }
  .tw-group-1 h1 {
    margin: 0px;
    margin-bottom: 0rem; }
  .tw-group-1 p {
    color: #94a3b8;
    font-size: 0.6875rem;
    margin: 0px;
    margin-bottom: 0px;
    font-weight: 400; }

* {
  padding: 0;
  margin: 0; }

.goal-management {
  width: 100%; }
  .goal-management button {
    cursor: pointer; }
  .goal-management .search-goal {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 0.875rem;
    gap: 8px;
    width: auto;
    font-size: 0.8rem;
    font-family: 500;
    color: #94a3b8;
    background: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 1; }
    .goal-management .search-goal .input-none {
      background: transparent;
      border: none;
      outline: none;
      font-size: 0.8rem;
      color: #94a3b8; }
      .goal-management .search-goal .input-none::-moz-placeholder {
        font-size: 0.8rem;
        color: #94a3b8; }
  .goal-management .select-category {
    padding: 12px 18px;
    gap: 8px;
    font-size: 0.8rem;
    width: 158px;
    height: 46px;
    font-size: 0.6875rem;
    font-family: 500;
    border-radius: 0.875rem;
    color: #94a3b8; }

.tw-btn {
  background: #126dd8;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border-radius: 0.875rem;
  cursor: pointer;
  padding: 0.6rem 1.3rem;
  font-size: 0.8215rem;
  color: #f4f7fa;
  font-weight: 500; }
  .tw-btn:hover {
    transition: all 800ms ease-in-out;
    background: #408adf; }

.tw-btn-transparent {
  color: #126dd8;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border-radius: 0.875rem;
  cursor: pointer;
  padding: 0.6rem 1.3rem;
  font-size: 0.8215rem;
  background: transparent;
  font-weight: 500; }
  .tw-btn-transparent:hover {
    transition: all 800ms ease-in-out;
    text-decoration: underline;
    text-underline-offset: 5px; }

.tw-btn-outline {
  color: #126dd8;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border-radius: 0.875rem;
  cursor: pointer;
  padding: 0.6rem 1.3rem;
  font-size: 0.8215rem;
  outline: 1px solid #cccccc;
  background: transparent;
  font-weight: 500; }
  .tw-btn-outline:hover {
    transition: all 800ms ease-in-out;
    outline: none;
    color: white;
    background: #126dd8; }

.management-switcher {
  transition: all 800ms ease-in-out; }

.tw-table-striped tbody tr:nth-of-type(even) {
  background-color: #f4f7fa; }

.tw-table-striped thead tr {
  border-top: 1px solid #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
  border-left: 0px solid #dee2e6 !important;
  border-right: 0px solid #dee2e6 !important;
  font-size: 0.8215rem;
  font-weight: 500;
  color: #334155; }

.tw-table-striped thead tr th {
  width: auto; }

.tw-table-striped tbody tr th,
.tw-table-striped tbody tr td {
  font-size: 0.8215rem;
  font-weight: 500;
  color: #0f172a;
  border: none !important; }

.tw-table-striped tbody tr .date-text {
  color: #94a3b8;
  font-size: 0.875rem;
  font-weight: 400; }

.tw-table-striped tbody tr .language-box {
  display: flex;
  gap: 0.5rem;
  align-items: center; }
  .tw-table-striped tbody tr .language-box img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: contain; }

.date-text-light {
  color: #94a3b8;
  font-size: 0.8215rem;
  font-weight: 400; }

.date-text-md {
  color: #94a3b8;
  font-size: 0.8215rem;
  font-weight: 500; }

.show-rows-select {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  gap: 8px;
  font-size: 0.8215rem;
  color: #0f172a;
  font-weight: 500;
  justify-content: center;
  width: 10.875rem;
  height: 42px;
  background: #f4f7fa;
  border: 1px solid #cccccc;
  border-radius: 12px; }

.tw-pagination-wrapper {
  width: auto;
  height: auto; }
  .tw-pagination-wrapper .tw-pagination {
    display: flex !important;
    align-items: center !important;
    gap: 0.5rem !important;
    color: #94a3b8; }
    .tw-pagination-wrapper .tw-pagination .tw-paginate-bullets {
      font-size: 0.8215rem;
      color: #94a3b8;
      font-weight: 500;
      cursor: pointer; }
    .tw-pagination-wrapper .tw-pagination .paginate-active {
      background: #2463ae;
      color: white;
      font-size: 0.8215rem;
      border: none;
      cursor: pointer; }
    .tw-pagination-wrapper .tw-pagination .tw-pagination-item {
      height: 30px;
      cursor: pointer;
      width: 30px;
      border: 1px solid #cccccc;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.8215rem;
      font-weight: 500;
      transition: all 800ms ease-in-out; }
      .tw-pagination-wrapper .tw-pagination .tw-pagination-item:hover {
        background: #2463ae;
        color: white;
        border: none; }

.status-completed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 10px 2px 2px;
  width: fit-content;
  gap: 0.5rem;
  background: #bbf7d0;
  color: #22c55e;
  height: 24px;
  border-radius: 96px; }
  .status-completed div {
    display: block;
    font-size: 8px;
    height: auto; }
  .status-completed p {
    display: block;
    font-size: 0.8215rem;
    font-weight: 500; }

.status-in-progress {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 10px 2px 2px;
  width: fit-content;
  gap: 0.5rem;
  background: #d1f0fa;
  color: #0c4e6e;
  height: 24px;
  border-radius: 96px; }
  .status-in-progress span {
    display: block;
    font-size: 5px; }
  .status-in-progress p {
    display: block;
    font-size: 0.8215rem;
    font-weight: 500; }

.progress-wrapper {
  width: 250px;
  height: 5px;
  border-radius: 30px;
  background: #cfe4fc;
  overflow: hidden;
  position: relative; }

.tw-progress {
  background: #126dd8;
  border-radius: 30px;
  height: 5px;
  transition: width 0.5s ease-in-out; }

.tw-progress-bar {
  display: flex;
  align-items: center;
  gap: 8px; }

.progress-count {
  display: flex;
  align-items: center;
  gap: 8px; }

.tw-performance .performance-rating {
  width: 24rem;
  height: auto;
  min-width: 24rem;
  padding: 1.1rem;
  border: 1px solid #cccccc;
  border-radius: 1rem; }
  .tw-performance .performance-rating .rating-heading {
    font-size: 1rem;
    font-family: 500;
    color: #0f172a; }
  .tw-performance .performance-rating h1 {
    font-size: 4rem;
    font-weight: 400;
    margin: 0;
    margin-bottom: 0px;
    color: #0f172a; }
  .tw-performance .performance-rating .stars-text {
    font-size: 0.8215rem;
    color: #94a3b8;
    font-weight: 500; }
  .tw-performance .performance-rating .self-feedback {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    gap: 8px;
    width: 100%;
    height: auto;
    background: #f4f7fa;
    border-radius: 12px; }
    .tw-performance .performance-rating .self-feedback h2 {
      color: #0f172a;
      font-weight: 500;
      font-size: 1rem;
      margin-bottom: 0;
      margin: 0; }
    .tw-performance .performance-rating .self-feedback p {
      font-size: 0.8215rem;
      color: #334155;
      line-height: 1.8;
      font-weight: 400;
      text-align: start; }

.tw-performance .performance-feedback {
  background: white; }
  .tw-performance .performance-feedback h1 {
    font-size: 1.3rem;
    font-family: 500;
    color: #0f172a;
    margin-bottom: 1rem; }
  .tw-performance .performance-feedback .performance-feedback-wrapper {
    max-height: 30rem;
    width: 100%;
    padding: 1rem;
    overflow-y: hidden;
    background: white; }
    .tw-performance .performance-feedback .performance-feedback-wrapper::-webkit-scrollbar {
      width: 0.6rem; }
    .tw-performance .performance-feedback .performance-feedback-wrapper::-webkit-scrollbar-thumb {
      background: #126dd8;
      border-radius: 1.5rem;
      height: 3rem; }
    .tw-performance .performance-feedback .performance-feedback-wrapper::-webkit-scrollbar-track {
      background: #f0f0f0;
      border-radius: 1.5rem; }
    .tw-performance .performance-feedback .performance-feedback-wrapper:hover {
      overflow-y: auto; }
    .tw-performance .performance-feedback .performance-feedback-wrapper .performance-feedback-content {
      height: auto;
      width: 100%;
      padding-top: 1.5rem;
      border-left: 1px solid #cccccc; }
      .tw-performance .performance-feedback .performance-feedback-wrapper .performance-feedback-content .content-box {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        padding-left: 1rem; }
        .tw-performance .performance-feedback .performance-feedback-wrapper .performance-feedback-content .content-box .bulletin-dot {
          width: 0.8215rem;
          height: 0.8215rem;
          border-radius: 50%;
          background: #f8faff;
          border: 4px solid #126dd8;
          position: absolute;
          top: 0;
          left: -0.5rem; }
        .tw-performance .performance-feedback .performance-feedback-wrapper .performance-feedback-content .content-box h1 {
          font-weight: 500;
          font-size: 1rem;
          margin-bottom: 0.5rem;
          color: #0f172a; }
        .tw-performance .performance-feedback .performance-feedback-wrapper .performance-feedback-content .content-box .group-1 {
          display: flex;
          gap: 1rem; }
          .tw-performance .performance-feedback .performance-feedback-wrapper .performance-feedback-content .content-box .group-1 img {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            object-fit: cover; }
          .tw-performance .performance-feedback .performance-feedback-wrapper .performance-feedback-content .content-box .group-1 .feedback-box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 1rem;
            gap: 8px;
            width: fit-content;
            height: auto;
            background: #f4f7fa;
            border-radius: 0px 12px 12px 12px; }
            .tw-performance .performance-feedback .performance-feedback-wrapper .performance-feedback-content .content-box .group-1 .feedback-box p {
              color: #94a3b8;
              font-size: 0.875rem;
              margin-bottom: 0px;
              margin: 0px;
              font-weight: 400; }
            .tw-performance .performance-feedback .performance-feedback-wrapper .performance-feedback-content .content-box .group-1 .feedback-box h2 {
              font-size: 0.875rem;
              font-weight: 500;
              color: #0f172a;
              margin-bottom: 0px;
              margin: 0px; }
            .tw-performance .performance-feedback .performance-feedback-wrapper .performance-feedback-content .content-box .group-1 .feedback-box .feedback-statement {
              color: #334155;
              font-size: 0.8215rem;
              font-weight: 400; }
        .tw-performance .performance-feedback .performance-feedback-wrapper .performance-feedback-content .content-box .no-feedback {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding: 1rem;
          gap: 8px;
          width: fit-content;
          height: 54px;
          color: #334155;
          font-size: 0.875rem;
          font-weight: 400;
          top: 487px;
          background: #f4f7fa;
          border-radius: 0px 12px 12px 12px; }

.block {
  display: block; }

.hidden {
  display: none;
  width: 0;
  height: 0; }

.tw-border-r-b {
  border-left: 1px solid #cccccc; }

.tw-modal-sm {
  padding: 1.5rem;
  width: 35rem;
  height: auto;
  background: #ffffff;
  border-radius: 2.5rem; }
  .tw-modal-sm .tw-modal-header {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center; }
    .tw-modal-sm .tw-modal-header h1 {
      font-size: 1.3rem;
      color: #0f172a;
      font-weight: 500;
      margin-bottom: 5px; }
    .tw-modal-sm .tw-modal-header p {
      margin-bottom: 0px;
      margin: 0;
      color: #334155;
      font-size: 0.9rem;
      font-weight: 400; }
  .tw-modal-sm .tw-modal-sm-body {
    display: flex;
    margin-top: 3rem;
    flex-direction: column;
    gap: 1rem;
    align-items: start; }
    .tw-modal-sm .tw-modal-sm-body .tw-form-group {
      display: flex;
      flex-direction: column;
      gap: 0.5rem; }
      .tw-modal-sm .tw-modal-sm-body .tw-form-group .tw-language-choice p {
        margin-bottom: 0;
        color: #0f172a;
        font-size: 0.8215rem;
        font-weight: 500; }
      .tw-modal-sm .tw-modal-sm-body .tw-form-group .tw-language-choice img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        object-fit: cover; }
      .tw-modal-sm .tw-modal-sm-body .tw-form-group .tw-form-labels {
        color: #334155;
        font-size: 0.9rem;
        font-weight: 600; }
      .tw-modal-sm .tw-modal-sm-body .tw-form-group .tw-form {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px;
        width: 33rem;
        height: 40px;
        background: #ffffff;
        border: 1px solid #cccccc;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        align-self: stretch;
        flex-grow: 0;
        color: #94a3b8;
        font-size: 0.6875rem;
        font-weight: 400; }
        .tw-modal-sm .tw-modal-sm-body .tw-form-group .tw-form::-moz-placeholder {
          font-size: 0.8rem;
          color: #94a3b8; }
      .tw-modal-sm .tw-modal-sm-body .tw-form-group textarea {
        height: 160px;
        outline: none; }
      .tw-modal-sm .tw-modal-sm-body .tw-form-group .tw-date {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px;
        width: 16rem;
        height: 40px;
        background: #ffffff;
        border: 1px solid #cccccc;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        align-self: stretch;
        flex-grow: 0;
        color: #94a3b8;
        font-size: 0.6875rem;
        font-weight: 400; }

.tw-language-choice-active {
  padding: 12px;
  gap: 12px;
  width: fit-content;
  height: auto;
  background: #ecf4fe;
  border: 1px solid #126dd8;
  border-radius: 0.875rem; }

.dots-menu {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  position: absolute;
  right: 0;
  top: 3rem;
  width: 12.5rem;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(19, 27, 40, 0.08);
  border-radius: 12px;
  z-index: 99; }
  .dots-menu .menu-content {
    cursor: pointer;
    transition: all 800ms ease-in-out; }
    .dots-menu .menu-content:hover {
      transition: all 800ms ease-in-out;
      text-decoration: underline;
      text-underline-offset: 5px; }
    .dots-menu .menu-content p {
      color: #0f172a;
      font-family: 500;
      font-size: 0.6875rem; }

.tw-topics {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .tw-topics button {
    float: right; }
  .tw-topics p {
    color: #0f172a;
    font-size: 0.875rem;
    font-weight: 400; }

.tw-line {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
  height: 0.5px;
  background: #cccccc;
  border-radius: 30px; }

.tw-text-light {
  color: #334155;
  font-weight: 400;
  font-size: 0.875rem;
  margin-bottom: 0;
  margin: 0; }

.tw-text-md {
  color: #0f172a;
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 0;
  margin: 0; }

.tw-text-md-1 {
  color: #0f172a;
  font-weight: 500;
  font-size: 0.9rem;
  margin-bottom: 3px;
  margin: 0; }

.tw-text-lighter {
  color: #94a3b8;
  font-weight: 400;
  font-size: 0.8rem;
  margin-bottom: 0;
  margin: 0; }

.tw-notes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 8px;
  width: 100%;
  height: auto;
  background: #f4f7fa;
  border-radius: 8px;
  align-self: stretch; }
